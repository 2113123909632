import axios from 'axios';
import KhDispatcher from '../KhDispatcher';
import { url, dispatchError } from './util';

const ReportDataManager = {
    getReports: async function(page) {
        try {
            const response = await axios.get(url('reports/1/list'));
            KhDispatcher.dispatch({
                type: 'reports/loaded',
                data: response.data,
            });

        } catch (err) {
            dispatchError('reports/load-error', err);
        }
    },
    getReport: async function(id) {
        try {
            const response = await axios.get(url('reports/'+id));
            KhDispatcher.dispatch({
                type: 'report/loaded',
                id,
                data: response.data,
            });
        } catch (err) {
            err.id = id;
            dispatchError('report/load-error', err);
        }
    }, 
    updateReport: async function(id, body, oldValue) {
        try {
            const response = await axios.put(url('reports/' + id), body);
            KhDispatcher.dispatch({
                type: 'report/updated',
                id,
                data: response.data
            });
        } catch (err) {
            dispatchError('report/update-error', err, id, undefined, oldValue);
        }
    },
    createReport: async function(body, fakeId) {
        try {
            const response = await axios.post(url('reports/'), body);
            KhDispatcher.dispatch({
                type: 'report/created',
                data: response.data,
                id: response.data._id,
                fakeId
            });
        } catch (err) {
            dispatchError('report/create-error', err, undefined, fakeId);
        }
    },
    deleteReport: async function(id) {
        try {
            await axios.delete(url('reports/'+id));
            KhDispatcher.dispatch({
                type: 'report/deleted'
            });
        } catch (err) {
            dispatchError('report/delete-error', err);
        }
    }
}

export default ReportDataManager;