import React from 'react';
import { Modal } from 'react-bootstrap';

class ModalView extends React.Component {
    render() {
        if (!this.props.currentModal) {
            return null;
        }

        return (
            <Modal show={true} onHide={() => this.props.dismissModal()}>
                {this.props.currentModal}
            </Modal>
        );
    }
}

export default ModalView;