import React from 'react';
import { Spinner, ListGroup, Form, Button, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/components/util.css'
import '../css/main.css'
import { url, dispatchError } from '../data_managers/util';
import axios from 'axios';

export class Separator extends React.Component {
    render() {
        return <span className="text-muted ml-1 mr-1">|</span>;
    }
}

export function LoadingView() { 
    return <Spinner animation="border" role="status" />
}

export function CenteredLoadingView() {
    return <div className="loading-container"><LoadingView /></div>;
}

export function EmptyListText(props) {
    return <div className={"empty-list-text " + props.className}><small className="text-muted">{props.children}</small></div>;
}

export function ListHeader(props) {
    return (
        <div style={props.style} className='list-header flex-sb'>
            <span className="ml-2"><h5 className="m-0">{props.children}</h5></span>
        </div>
    );

}

export class EditableList extends React.Component {
    constructor() {
        super();
        this.newItemText = React.createRef();
        this.newItemSelect = React.createRef();
        
        this.state = {
            cat:[],
            cats:null,
        };
    }

    async cat() {
        var total = 0;
        var reportIDs = [];
        const response = await axios.get(url('categories'));
        console.log('dddd 11',response.data);
        await this.setState({cats:response.data});
    }

    componentWillReceiveProps(nextProps, nextState){
        this.cat(); 
    }
    componentWillMount() {
        this.cat();
    }

    render() {
        const onKeyDown = (e) => {
            if (e.keyCode === 13) {
                var value = this.newItemText.current.value;
                value = value.replace(/\s*$/, "");
                if (!value || value === '') {
                    return;
                }
                this.props.onNewItem(value);
                this.newItemText.current.value = '';
            }
        }

        const onSelectChange = () => {
            var value = this.newItemSelect.current.value;
            value = value.replace(/\s*$/, "");
            this.props.onNewItem(value);
            this.newItemSelect.current.selectedIndex = 0;
        };
        console.log('dddd',this.state.cats);
        return (
            this.props.category?
            
            <ListGroup className='editable-list'>
                {this.props.items.size > 0 ? this.props.items.map(i =>
                    <ListGroup.Item className="p-1" key={i.id}>
                        <div className="flex-sb">
                            <span title={i.name} className="ml-1 text-ellipsis editable-list-item-name">{i.name}</span>
                            <Button onClick={() => this.props.onDeleteItem(i.id)} variant="link">&times;</Button>
                        </div>
                    </ListGroup.Item>) :
                    <EmptyListText className='border'>Add a new item below.</EmptyListText>}
                <ListGroup.Item>
                    {this.state.cats ? 
                        <><Form.Control as="select" onChange={onSelectChange} ref={this.newItemSelect} defaultValue="default" size="sm">
                            <option value="default" disabled>Select item...</option>
                            {this.state.cats.map(v => <option key={v.id} value={v.name}>{v.name}</option>)}
                        </Form.Control> 
                        <label style={{paddingTop:9}} className="form-label">Or Create New Category</label>
                        <Form.Control placeholder="New item" onKeyDown={onKeyDown} type="text" size="sm" ref={this.newItemText} /></>:
                        <Form.Control placeholder="New item" onKeyDown={onKeyDown} type="text" size="sm" ref={this.newItemText} /> }
                </ListGroup.Item>
            </ListGroup>
            :
            <ListGroup className='editable-list'>
                {this.props.items.size > 0 ? this.props.items.map(i =>
                    <ListGroup.Item className="p-1" key={i.id}>
                        <div className="flex-sb">
                            <span title={i.name} className="ml-1 text-ellipsis editable-list-item-name">{i.name}</span>
                            <Button onClick={() => this.props.onDeleteItem(i.id)} variant="link">&times;</Button>
                        </div>
                    </ListGroup.Item>) :
                    <EmptyListText className='border'>Add a new item below.</EmptyListText>}
                <ListGroup.Item>
                    {this.props.possibleValues ? 
                        <Form.Control as="select" onChange={onSelectChange} ref={this.newItemSelect} defaultValue="default" size="sm">
                            <option value="default" disabled>Select item...</option>
                            {this.props.possibleValues.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
                        </Form.Control> 
                        :
                        <Form.Control placeholder="New item" onKeyDown={onKeyDown} type="text" size="sm" ref={this.newItemText} /> }
                </ListGroup.Item>
            </ListGroup>
        );
    }
}

export function SymbolButton(props) {
    return (<Button className={props.className || ''} variant={props.variant || "primary"} size={props.size || "sm"} onClick={props.onClick}>
                <div className="flex-center">
                    <h4 className="mr-2 mb-0" ><strong>{props.symbol}</strong></h4>
                    <p className="m-0">{props.text}</p>
                </div>
            </Button>);
}

export function Header(props) {
    
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="">
                <Link className='white-link' to={'/'}>
                    <img height='32' width='32' alt='Kharabeesh logo' src="/kharabeesh-logo.png" />
                    <span className='ml-2'>Kharabeesh Financial</span>
                </Link>
                </Navbar.Brand>
                <div className="mr-auto d-flex flex-rows align-items-center">
                    {props.children}
                </div>
            { 
                /*props.userData.getLoadObject().getValue() ? 
                    <div className="d-flex flex-rows align-items-center">
                        <span className="white-link">Logged in as <strong>{props.userData.getLoadObject().getValue().name}</strong></span>
                        <Button variant='link' className="nav-link white-link underline-link" onClick={props.onLogout}>Logout</Button> 
                    </div> :
                    null*/
            }
        </Navbar>);
}