import React  from 'react';
import { CenteredLoadingView, EmptyListText, ListHeader } from '../util';
import moment from 'moment';
import { Link } from 'react-router-dom';
import EditReportModal from './modals/EditReportModal';
import '../../css/components/report_list.css'
import '../../css/main.css'

export default function ReportList(props) {
    const reportsLO = props.reportList.getLoadObject(props.pageNum);
    const loading = !reportsLO.isDone() || !reportsLO.hasOperated();

    const reports = reportsLO.getValue();
        
        var url = new URL(window.location.href);
        var c = url.searchParams.get("category");
        

    var pageNumVar1 = 1;
    var pageNumVar2 = 1;
    
    if (props.pageNum)
    {
        pageNumVar1 = parseFloat(props.pageNum)-1;
        if (pageNumVar1<=0)
            pageNumVar1 = 1;
        
        pageNumVar2 = parseFloat(props.pageNum) + 1;
            
    }
    
    let body = null;

    if (loading) {
        body = <CenteredLoadingView />;
    } else {
        if (!reports) {
            body = <EmptyListText>Error loading reports.</EmptyListText>;
        } else if (reports.length === 0) {
            body = <EmptyListText>No reports found. Create a report to get started.</EmptyListText>;
        } else {
            body = (<div className='list-group items-container' >
                {
                    
                    

                    reports.map(id => {
                        let r = props.reports.get(id).getValue();
                        let categoriesArr = r.categories;
                        var length = categoriesArr.length;

                        if(c==='none' && length > 0)
                        {
                            r = null;
                        }
                        
                        if(c && c!='none' && categoriesArr.indexOf(c)===-1)
                        {
                            r = null;
                        }

                        return (<ReportItem 
                                    deleteReport={props.deleteReport} 
                                    updateReport={props.updateReport} 
                                    userData={props.userData} 
                                    key={id} 
                                    users={props.users}
                                    pageNum={props.pageNum}
                                    r={r} />);
                    
                    })
                }
            </div>);
        }
    }
    
    return (
        <div className={"list-container " + props.className}>
            <ListHeader >Financial Reports</ListHeader>
            <div className='col-md-12'>
                <div className='col-md-7'>
                    s
                </div>
                <div className='col-md-5'>
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item"><props.linkFun to={'/report-list/'+pageNumVar1} className="page-link" >Previous</props.linkFun></li>
                        
                        <li class="page-item"><props.linkFun  to={'/report-list/'+pageNumVar2} className="page-link" >Next</props.linkFun></li>
                      </ul>
                    </nav> 
                </div> 
            </div> 
            <div className="border body-container">
                {body}
            </div>
        </div>);
}

class ReportItem extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {editing: false,pageNum:0,category:null,content:'block',counter:0};

        //const { category } = props.computedMatch.params;
        //const { pageNum } = props.computedMatch.params;
    }

    filter() {
        
    }
    componentWillUpdate() {
        
    }
    componentWillReceiveProps(nextProps) {
        
    }
    
    pageNum(page) {
        
    }

    render() {
        const { _id } = this.props.userData.getLoadObject().getValue();
        let self = this;
        return (
                <div>
            {this.props.r ? 
                
                <li className='list-group-item list-group-item-action flex-column align-items-start'>
                <div className='d-flex w-100 justify-content-between'>
                    <div className="report-link-container">
                        <Link to={'report/' + this.props.r._id}><h5 title={this.props.r.name} className='mb-4 report-link text-ellipsis'>{this.props.r.name}</h5></Link>
                    </div>
                    <small className='ml-2'>Last modified: {new moment(this.props.r.modifyDate).format('YYYY-MMM-DD HH:mm')}</small>
                </div>
                <div className='d-flex w-100 user-view justify-content-between' >
                    <UserView users={this.props.users} prefix='Owner:' userId={this.props.r.ownerId} />
                    {_id === this.props.r.ownerId ? <button className='btn btn-link p-0' onClick={() => this.setState({editing: true})}>Edit</button> : null}
                </div>
                <div className='d-flex flex-wrap w-100'>
                    {this.props.r.categories.map(c => <Link key={c} to={'/?category='+encodeURI(c)}><small className='border text-secondary rounded mr-2 mb-2 p-1' >{c}</small></Link>)}
                </div>
                <EditReportModal 
                    show={this.state.editing}
                    deleteReport={this.props.deleteReport} 
                    dismissModal={() => this.setState({editing: false})} 
                    users={this.props.users} 
                    report={this.props.r} 
                    updateReport={this.props.updateReport} 
                    userData={this.props.userData} />
                    </li>
                        : 
                        ''
                }
                </div>);
        }
    }

// TODO: extract to utils and use in report modal
function UserView(props) {
    const userLo = props.users.get(props.userId);
    if (userLo.isDone() && userLo.hasOperated() && !userLo.hasError()) {
        const userName = userLo.getValue().name || userLo.getValue().email;
        return <small>{props.prefix} <span className='text-secondary' >{userName}</span></small>;
    }
    return <small>{props.prefix} ...</small>;
}