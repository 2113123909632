import React from 'react';
import { Flat } from './report/FlexMonster';
import { SymbolButton } from './util';
import { Link } from 'react-router-dom';
import { Header } from './util';

export default function FlatTableView(props) {
    const dataSource = props.computedMatch.params.dataSource;
    return (
        <>
            <Header {...props}>
                <Link to={'/report/new:' + dataSource}>
                    <SymbolButton className="ml-2 mt-0 mb-0" symbol="+" text="Create report" />
                </Link>
            </Header>
            <Flat filename={'/api/data/' + dataSource} />
        </>
    );
}