import React from 'react';
import * as FlexmonsterReact from 'react-flexmonster';

export class Flat extends React.Component {
    render() {
        return <FlexmonsterReact.Pivot
            licenseKey="Z7HH-XIG73L-63624G-4W4L5G-2I3D40-2I6542-4Q6I2J-5A0S34-4W1S3H-6H4T"
            height="100%"
            report={{ options: { grid: { type: 'flat' } }, dataSource: { dataSourceType: 'csv', filename: this.props.filename } }} />;
    }
}
