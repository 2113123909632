import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import LoadObjectOperation from '../load_object/LoadObjectOperation';
import KhDispatcher from '../KhDispatcher';
import ReportDataManager from '../data_managers/ReportDataManager';
import LoadObjectMap from '../load_object/LoadObjectMap';

class ReportStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectMap((ids) => ids.forEach(id => KhDispatcher.dispatch({type: 'report/load', id})));
    }

    reduce(state, action) {
        switch(action.type) {
            case 'report/load':
                ReportDataManager.getReport(action.id);
                return state.set(action.id, LoadObject.loading());
            case 'report/loaded':
                return state.set(action.id, LoadObject.withValue(action.data));
            case 'reports/loaded':
                return state.merge(action.data.map(r => [r._id, LoadObject.withValue(r)]));
            case 'report/load-error':
                return state.set(action.id, LoadObject.withError(action.error));
            case 'report/update':
            {
                const oldReport = state.get(action.id);
                ReportDataManager.updateReport(action.id, action.data, oldReport.getValue());
                return state.set(action.id, oldReport.setOperation(LoadObjectOperation.Updating));
            }
            case 'report/updated':
                return state.set(action.id, LoadObject.withValue(action.data).removeOperation());
            case 'report/update-error':
                return state.set(action.id, LoadObject.withValue(action.oldValue).setError(action.error).removeOperation());
            case 'report/create':
                ReportDataManager.createReport(action.data, action.fakeId);
                return state.set(action.fakeId, LoadObject.creating().setValue(action.data));
            case 'report/created':
            {
                const newReport = action.data;
                newReport.fakeId = action.fakeId;
                return state.delete(action.fakeId).set(action.id, LoadObject.withValue(newReport));
            }
            case 'report/create-error':
            {
                const newReport = state.get(action.fakeId);
                return state.set(action.fakeId, newReport.setError(action.error).removeOperation());
            }
            case 'report/delete':
            {
                const report = state.get(action.id);
                ReportDataManager.deleteReport(action.id);
                return state.set(action.id, report.setOperation(LoadObjectOperation.Deleting));
            }
            case 'report/deleted': 
            {
                return state.delete(action.id);
            }
            case 'report/delete-error':
            {
                const report = state.get(action.id);
                return state.set(action.id, report.setError(action.error).removeOperation());
            }
            default: 
                return state;
        }
    }
}

export default new ReportStore();
