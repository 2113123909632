import React, {Component} from 'react';
import axios from 'axios'
//import { css } from 'react-emotion';
import { css, cx } from 'emotion';
import { ClipLoader } from 'react-spinners';
//import ClipLoader from 'react-spinners/ClipLoader';
import { url, dispatchError } from '../../data_managers/util';
import EditReportModal from './modals/EditReportModal';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
 
`;


class ReportListing extends Component {
 
    constructor(prop){
        super(prop);
        this.state = {
            students: [],
            from :'',
            per_page :'',
            last_page:'',
            current_page:1,
            next_page_url:'',
            first_page_url:'',
            last_page_url:'',
            prev_page_url:'',
            searchQuery:'',
            test_type:'',
            sortColumn:'',
            sortOrder:'',
            loading:true,
            allItems:0,
            editing: false,
            repoType: 'my',
            startDate:'',
            ownername:'',
            search:'',
            URL:'',
            URLAll:''
        };
        this.pagination = this.pagination.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.sortTable = this.sortTable.bind(this);
    
    }
    
    handleChange = date => {
        this.setState({
          startDate: date
        });

        if (date)
        {
            var month = date.getMonth()+Number(1);
            date = date.getUTCFullYear()+'-'+month+'-'+date.getDate();
            this.keyPress({target:{name:'date',value:date},keyCode:13},'date');
        }
        else
        {
            this.gitAllitems();
            let urll = url('reports/1/list');
            this.getCallApi(urll);
        }
        
        
    };

    async componentWillReceiveProps(nextProps, nextState){
        
        var urlVar = new URL(window.location.href);
        var repoType = urlVar.searchParams.get("repoType");
        repoType = repoType!==null?repoType:this.state.repoType;
        //this.refs.searchField.value = '';
        await this.setState({searchQuery:'',repoType:repoType,startDate:'',ownername:'',
            search:''});
        
        let urll = url('reports/1/list');
        this.getCallApi(urll);
        this.gitAllitems(); 
    }
    componentWillMount() {
        this.gitAllitems();

        let urll = url('reports/1/list');
        this.getCallApi(urll);
    }
    async gitAllitems(search=null) {
        
        const { email } = this.props.userData.getLoadObject().getValue();
        
        let urll = url('reports/none/list');
        var urlVar = new URL(window.location.href);
        var catNames = urlVar.searchParams.get("category");
        if (catNames)
        {
            urll = urll+'/'+catNames;
        }
        else
            urll = urll+'/none';

        var repoType = urlVar.searchParams.get("repoType");
        
        if (search)
            urll = urll+'/'+search;
        else
            urll = urll+'/none';

        if (email == 'baseAccount')
            repoType = 'admin';

        if (repoType)
            urll = urll + '/'+repoType;
        else
            urll = urll + '/'+this.state.repoType;
        
        if (urll !== this.state.URLAll)
        await axios.get(urll)
            .then(json => {
 
                let response = json.data;
                let allItems = response.length;

                this.setState({
                    allItems:allItems,
                    URLAll:urll
                });
                 
            });
    }
    componentWillUnmount() {
    }
    componentDidMount(){
        // let url = '/api/tests?type='+this.props.match.params.test;
        /*let urll = url('reports/1/list');
        this.getCallApi(urll);*/
    }
 
    async getCallApi(urll,search=null){
        const { email } = this.props.userData.getLoadObject().getValue();

        let next_page_url = url('reports/1/list');
        let prev_page_url = url('reports/2/list');

        var urlVar = new URL(window.location.href);
        var catNames = urlVar.searchParams.get("category");
        if (catNames)
        {
            var cc = {catNames};
            var arr = JSON.stringify(cc);

            urll = urll+'/'+catNames;
        }
        else
            urll = urll+'/null';

        this.setState({
            searchQuery:search
        });

        if (search)
            urll = urll+'/'+search;
        else
            urll = urll+'/null';

        var repoType = urlVar.searchParams.get("repoType");
        if (email == 'baseAccount')
            repoType = 'admin';
        if (repoType)
            urll = urll + '/'+repoType;
        else
            urll = urll + '/'+this.state.repoType;

        console.log('URL',urll);
        //urll = encodeURI(urll);
        if (urll !== this.state.URL)
        await axios.get(urll)
            .then(json => {
 
                let response = json.data;
                let data = response;
        
                this.setState({
                    students: data,
                    from:response.from,
                    per_page:response.length,
                    last_page:2,
                    current_page:this.state.current_page,
                    next_page_url:next_page_url,
                    first_page_url:'',
                    last_page_url:'',
                    prev_page_url:prev_page_url,
                    path:response.path,
                    loading: false,
                    URL:urll
                });

            });
    }
 
    pagination(target,e,i=null){
        e.preventDefault();
        this.setState({
            loading: true
        });
        let urll;
        switch(target){
            case 'pre':
                if(this.state.current_page != null){
                    let pre = this.state.current_page-1;
                    if (pre==0)
                    {
                        pre=1;
                    }
                    let urll = url('reports/'+pre+'/list');

                    this.setState({current_page:pre,next_page_url:''});
                    
                    let searchQuery = this.state.searchQuery !==undefined ? this.state.searchQuery : null;
                        
                    this.getCallApi(urll,searchQuery);
                }
            break;
            case 'next':
                if(this.state.current_page != null){
                    let nex = this.state.current_page+1;

                    if (this.state.students.length==0)
                    {
                        nex = nex -1;
                    }
                    
                    let urll = url('reports/'+nex+'/list');

                    this.setState({current_page:nex});
                    this.getCallApi(urll);
                    
                    if (Math.round(this.state.allItems / 10) == this.state.current_page)
                    {
                        this.setState({next_page_url:'#'});
                    }
                    
                }
            break;
            case 'btn-click':
                urll = this.state.path+'?page='+i;
                 
                this.getCallApi(urll);
                 
            break;
            case 'test_type':
 
                let val = e.target.value=='All'?'':e.target.value;
                this.setState({
                    test_type : val 
                });
                let searchQuery = this.state.searchQuery !==undefined ? this.state.searchQuery : '';
                 
                if(e.target.value != null){
                    //let url = constant.APP_URL+'api/get-student?searchColumn=name&searchQuery='+searchQuery+'&test_type='+val;
                    let urll = url('reports/1/list');
                    this.getCallApi(urll);
                }
            break;
             
        }
        this.setState({
            loading: false
        });
    }
 
    sortTable(key,e){
        e.preventDefault();
        this.state.sortColumn = key;
        this.state.sortOrder = this.state.sortOrder=='' || this.state.sortOrder=='asc'?'desc':'asc';

        let urll = url('reports/1/list');
        this.getCallApi(urll);
    }
 
    async keyPress(e,fieldName=null){
        if (fieldName==='date')
        {
            e.target.name='date';
            e.target.value=e.target.value;
        }
      if(e.keyCode == 13){
        this.setState({
            loading: true
        });
        this.setState({
            searchQuery:e.target.value
        });
        //alert(e.target.name);
        let test_type = this.state.test_type !==undefined ? this.state.test_type : '';
        
        if (e.target.name !== 'none')
        {
            if (e.target.name == 'search')
                document.getElementById("ownername").value="";

            if (e.target.name=='search' || e.target.name=='owner')
            this.setState({startDate:''});

            var textSearch = e.target.value;
        }
        else
            var textSearch = 'none';

        if (e.target.name=='ownername')
        {
            document.getElementById("search").value="";
            this.setState({startDate:''});
            textSearch='owner:'+textSearch
        }
        else if (e.target.name=='date')
        {
            document.getElementById("search").value="";
            document.getElementById("ownername").value="";
            textSearch='date:'+textSearch
        }
        
        //let url = constant.APP_URL+'api/get-student?searchColumn=name&searchQuery='+e.target.value+'&test_type='+test_type;
        let urll = url('reports/1/list');
        this.getCallApi(urll,textSearch);

        this.gitAllitems(textSearch);

        this.setState({
            loading: false
        });
      }
    }
    
    /*handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }*/

    handleInputChange = data => {
        
        this.setState({
          [data.target.name]: data.target.value
        });
    }

    render() {

        const { _id } = this.props.userData.getLoadObject().getValue();
        let table_row;
 
        if(this.state.students.length>0){
            let tr;
            table_row = this.state.students.map((row, index)=>{
 
                return <tr key={index}>
                    <td>
                        <h5 title={row.name} className='mb-4 report-link text-ellipsis'><Link to={'report/' + row._id}>{row.name}</Link></h5>
                    </td>
                     
                    <td><UserView users={this.props.users} prefix='Owner:' userId={row.ownerId} /></td>
                    <td><FormatDate date={row.createDate}/></td>
                    <td>
                        <FormatDate date={row.modifyDate}/>
                    </td>    
                    <td>
                        {_id === row.ownerId ? <button data-target={'#divy_'+row._id} className='btn btn-link p-0' onClick={() => this.setState({editing: true,activeForm:row._id})}>Edit</button> : null}

                        <EditReportModal 
                                show={this.state.editing}
                                activeForm={this.state.activeForm}
                                id={row._id}
                                deleteReport={this.props.deleteReport} 
                                dismissModal={() => this.setState({editing: false})} 
                                users={this.props.users} 
                                report={row} 
                                updateReport={this.props.updateReport} 
                                userData={this.props.userData} />
                    </td>
                </tr>;
 
            });
        }else{
            table_row = null
        }
 
        let rows = [];
        for (let i = 1; i <= this.state.last_page; i++) {
            rows.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={(e)=>this.pagination('btn-click',e,i)}>{i}</a></li>);
        }
        

        return (
            /*onClick={(e)=>{this.sortTable('score',e)}}*/

            <div className="page-wrapper col-md-8" style={{padding:0,maxWidth:'68.666667%'}}>
 
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                    </div>
 
                </div>
 
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12" style={{padding:0}}>
                            <div className="card card-default">
                                <div className="card-header">
                                    <div className="card-actions">
                                        <a className="" data-action="collapse"><i className="ti-minus"></i></a>
 
                                    </div>
                                    <h4 className="card-title m-b-0">Reports List</h4>
                                </div>
                                <div className="card-body collapse show">
                                    <div className="d-flex no-block">
                                        <div className="ml-auto col-md-12"> 
                                            <div className='col-md-4' style={{float:'left',marginBottom:10}}>Showing {this.state.per_page} of {this.state.allItems}</div>
                                            {/*<input type="text" name={"search"} 
                                            onKeyDown={(e)=>{this.keyPress(e)}} ref="searchField" className="form-control col-md-3" style={{float:'right',marginBottom:'12px'}}/>*/}
                                        </div>
                                    </div>
 
                                    <div className="table-responsive">
 
                                        {!this.state.loading ?
                                            <div>
                                                <table className="table product-overview">
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            Report Name<br />
                                                            <input className="search" 
                                                            onKeyDown={(e)=>{this.keyPress(e)}}
                                                            autoComplete="off" id="search" name="search" type="text"
                                                            value={this.state.search} 
                                                            onChange={this.handleInputChange}
                                                            />
                                                        </th>
                                                        <th>
                                                            Owner
                                                            <br />
                                                            <input className="search" style={{width:132}} 
                                                            onKeyDown={(e)=>{this.keyPress(e)}}
                                                            autoComplete="off" id="ownername" name="ownername" type="text"
                                                            value={this.state.ownername} 
                                                            onChange={this.handleInputChange}
                                                            />
                                                        </th>
                                                        <th>
                                                            Created Date
                                                            <br />
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                onChange={this.handleChange}
                                                                dateFormat="yyyy-MM-dd"
                                                                //onKeyDown={(e)=>{this.keyPress(e)}}
                                                                id="date" 
                                                                name="date"
                                                                className="search"
                                                                autoComplete={'off'}
                                                              />

                                                              
                                                            
                                                        </th>
                                                        <th>Last Modified</th>
                                                        <th>Options</th>
                                                    </tr>
                                                    </thead>
 
                                                    <tbody>
 
                                                    {table_row}
                                                     
                                                    </tbody>
                                                </table>
                                                 
                                                <div className="dataTables_paginate paging_simple_numbers" id="example23_paginate">
                                                   <ul className="pagination justify-content-end">
                                                      <li className="page-item"><a className="page-link" href="#" onClick={(e)=>this.pagination('pre',e)}>Previous</a></li>
                                                      {//rows
                                                      }
                                                      <li className='page-item'><a className={
                                                        //Math.round(this.state.allItems / 10)
                                                          this.state.allItems < 10 == this.state.current_page?'page-link disable':'page-link'} href="#" onClick={(e)=>this.pagination('next',e)}>Next</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            :
                                            <div className='sweet-loading' style={{'textAlign':'center'}}>
                                                <ClipLoader
                                                    className={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportListing;

// TODO: extract to utils and use in report modal
function UserView(props) {
    const userLo = props.users.get(props.userId);
    if (userLo.isDone() && userLo.hasOperated() && !userLo.hasError()) {
        const userName = userLo.getValue().name || userLo.getValue().email;
        return <span>{userName}</span>;
    }
    return <small>{props.prefix} ...</small>;
}

function FormatDate(date) {
    var d = date.date;
    var datee = d.split('T')[0];

    return datee;
}