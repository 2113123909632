import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import KhDispatcher from '../KhDispatcher';
import LoadObjectState from '../load_object/LoadObjectState'
import DataSourcesDataManager from '../data_managers/DataSourcesDataManager';

class DataSourcesStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectState(() => {KhDispatcher.dispatch({type: "dataSources/load"})});
    }

    reduce(state, action)  {
        switch(action.type) {
            case 'dataSources/load': 
                DataSourcesDataManager.getDataSources();
                return state.setLoadObject(LoadObject.loading());
            case 'dataSources/loaded': 
                const loadedLO = state.getLoadObject();
                return state.setLoadObject(loadedLO.setValue(action.data).removeOperation());
            case 'dataSources/load-error':
                const errorLO = state.getLoadObject();
                return state.setLoadObject(errorLO.setError(action.error).removeOperation());
            default:
                return state;
        }
    }
}

export default new DataSourcesStore();
