import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import KhDispatcher from '../KhDispatcher';
import LoadObjectState from '../load_object/LoadObjectState'
import UserDataManager from '../data_managers/UserDataManager';

class LogInStatusStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectState(() => {});
    }

    reduce(state, action)  {
        switch(action.type) {
            case 'login/start':
                UserDataManager.logIn(action.email, action.password);
                return state.setLoadObject(LoadObject.loading());
            case 'login/success':
                return state.setLoadObject(LoadObject.withValue(true));
            case 'login/error':
                return state.setLoadObject(LoadObject.withError(action.error));
            default: 
                return state;
        }
    }
}

export default new LogInStatusStore();
