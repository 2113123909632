import KhDispatcher from '../KhDispatcher';

export function url(path) {
    const r = '/api/' + path;
    return r;
}

export function dispatchError(actionType, err, id, fakeId, oldValue) {
    if (!err.response) {
        return;
    }

    KhDispatcher.dispatch({
        type: actionType,
        error: typeof (err.response.data || '') === 'object' ? err.response.data : { message: err.toString() },
        id,
        fakeId,
        oldValue
    });
}