import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { EditableList, CenteredLoadingView } from '../../util';
import { List } from 'immutable';
import '../../../css/modals/edit_report.css'

export default class EditReportModal extends React.Component {
    constructor(props) {
        super(props);
        const report = props.report;
        this.state = {
            writers: List(report.writerIds),
            readers: List(report.readerIds),
            categories: List(report.categories),
        };
        this.name = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextState){

        const report = this.props.report;
        this.setState({
            writers: List(report.writerIds),
            readers: List(report.readerIds),
            categories: List(report.categories),
        });

    }

    getPossibleValues(currentVals, allPossibleVals) {
        return allPossibleVals.filter(v => !currentVals.includes(v));
    }

    getDisplayName(user){
        return user && (user.name || user.email);
    }

    toEditableListModel(ids) {
        return ids.map(id => ({id, name: this.getDisplayName(this.props.users.get(id).getValue())}));
    }

    getPossibleWriters() {
        return this.getPossibleValues(this.state.writers, this.allUserIds);
    }

    getPossibleReaders() {
        return this.getPossibleValues(this.state.readers, this.allUserIds);
    }

    deleteItem(list, id) {
        const index = list.findIndex(x => id === x);
        if (index === -1) {
            return list;
        }

        return list.delete(index);
    }

    addUserifNotExists(list, id) {
        if (!list.includes(id)) {
            return list.push(id);
        }
        return list;
    }

    onDeleteWriter = (id) => {
        this.setState({writers: this.deleteItem(this.state.writers, id)});
    }

    onNewWriter = (id) => {
        // If a user can write to a document, they can read it
        this.setState({writers: this.state.writers.push(id), readers: this.addUserifNotExists(this.state.readers, id)});
    }

    onDeleteReader = (id) => {
        // If a user can't read a document, they can't write to it
        this.setState({ readers: this.deleteItem(this.state.readers, id), writers: this.deleteItem(this.state.writers, id)});
    }

    onNewReader = (id) => {
        this.setState({ readers: this.state.readers.push(id) });
    }

    onNewCategory = (category) => {
        this.setState({categories: this.state.categories.push(category)});
    }

    onDeleteCategory = (category) => {
        this.setState({categories: this.deleteItem(this.state.categories, category)});
    }

    onSave = () => {
        const newReport = {
            name: this.name.current.value,
            writerIds: this.state.writers,
            readerIds: this.state.readers,
            categories: this.state.categories,
            reportContent: this.props.report.reportContent
        };
        if (this.props.new) {
            this.props.createReport(newReport);
        } else {
            this.props.updateReport(this.props.report._id, newReport);
        }
        this.props.dismissModal();
    }

    onDelete = () => {
        this.props.deleteReport(this.props.report._id);
        this.props.dismissModal();
    }

    render() {
        const allLo = this.props.users.get('all');
        if(!this.allUserIds ) {
            if (allLo.getValue()) { 
                const currentUser = this.props.userData.getLoadObject().getValue();
                this.allUserIds = allLo.getValue().filter(u => u._id !== currentUser._id).map(u => u._id);
            } else {
                return (<Modal.Body><CenteredLoadingView /></Modal.Body>);
            }
        }

        const titlePrefix = this.props.new ? 'New' : 'Edit';
        const writers = this.toEditableListModel(this.state.writers) || [];
        const possibleWriters = this.toEditableListModel(this.getPossibleWriters());

        const readers = this.toEditableListModel(this.state.readers) || [];
        const possibleReaders = this.toEditableListModel(this.getPossibleReaders());

        const categories = this.state.categories.map(c => ({id: c, name: c}));

        return (//this.props.id==this.props.activeForm
            
            <Modal id={'divy_'+this.props.id} show={this.props.id==this.props.activeForm?this.props.show:false} onHide={this.props.dismissModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{titlePrefix} "{this.props.report.name}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Report Name</Form.Label>
                            <Form.Control defaultValue={this.props.report.name} type="text" ref={this.name}/>
                        </Form.Group>
                        <div className="flex-sb-top">
                                <div className="permission-list">
                                    <Form.Group>
                                        <Form.Label>Readers</Form.Label>
                                        <EditableList items={readers} possibleValues={possibleReaders} onDeleteItem={this.onDeleteReader} onNewItem={this.onNewReader} category={false}/>
                                    </Form.Group>
                                </div>
                                <div className="permission-list">
                                    <Form.Group>
                                        <Form.Label>Writers</Form.Label>
                                        <EditableList items={writers} possibleValues={possibleWriters} onDeleteItem={this.onDeleteWriter} onNewItem={this.onNewWriter} category={false}/>
                                    </Form.Group>
                                </div>
                            </div>
                        <Form.Group>
                            <Form.Label>Categories</Form.Label>
                            <EditableList items={categories} onDeleteItem={this.onDeleteCategory} onNewItem={this.onNewCategory} category={true}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!this.props.new ? <Button style={{position: "absolute", left: '15px'}} variant='danger' onClick={this.onDelete}>Delete report</Button> : null}
                    <Button variant="secondary" onClick={this.props.dismissModal}>Cancel</Button>
                    <Button variant="primary" onClick={this.onSave}>Save</Button>
                </Modal.Footer>
            </Modal>
            );
    }
}