import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import MainView from './views/MainView';
import LoginView from './views/LoginView';
import ReportView from './views/ReportView';
import { CenteredLoadingView } from './views/util';
import ModalView from './views/ModalView';
import FlatTableView from './views/FlatTableView';

function App(props) {
  return (props.isReady ? 
    <div style={{height: '100vh', width: '100vw'}}>
        <Router>
            <ModalView currentModal={props.currentModal} dismissModal={props.dismissModal} />
            <Switch>
              <Route exact path="/login" render={() => <LoginView {...props} />} />
              <PrivateRoute exact path="/" {...props} component={MainView} />
              {/*<PrivateRoute exact path="/report-list/:pageNum" {...props} component={MainView} />*/}
              {/*<PrivateRoute exact path="/category/:Catid" {...props} component={MainView} />*/}
              <PrivateRoute path="/report/:reportId" {...props} component={ReportView} />
              <PrivateRoute {...props} path="/data/:dataSource" component={FlatTableView} />
              <Route render={() => <div>404</div>} />
            </Switch>
        </Router>
    </div>
     : 
   <div ><CenteredLoadingView /></div>);
}

export default App;
