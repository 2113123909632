import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import KhDispatcher from '../KhDispatcher';
import ReportDataManager from '../data_managers/ReportDataManager';
import LoadObjectState from '../load_object/LoadObjectState';
import LoadObjectMap from '../load_object/LoadObjectMap';

class ReportListStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectState(() => KhDispatcher.dispatch({type: 'reports/load'}));
    }

    reduce(state, action) {
        switch(action.type) {
            case 'reports/load':
                ReportDataManager.getReports(action.id); 
                return state.setLoadObject(LoadObject.loading());    
                //return state.set(action.id, LoadObject.loading());
            case 'reports/loaded':
                return state.setLoadObject(state.getLoadObject().setValue(action.data.map(r => r._id)).removeOperation());
            case 'reports/load-error':
                return state.setLoadObject(state.getLoadObject().setError(action.error).removeOperation());
            case 'report/created':
            case 'report/deleted':
                ReportDataManager.getReports();
                return state.setLoadObject(LoadObject.loading());
            default:
                return state;
        }
    }
}

export default new ReportListStore();