import React  from 'react';
import { CenteredLoadingView, EmptyListText, ListHeader } from '../util';
import moment from 'moment';
import '../../css/components/report_list.css'
import '../../css/main.css'
import ReportDataManager from '../../data_managers/ReportDataManager';
import { url, dispatchError } from '../../data_managers/util';
import axios from 'axios';
import { Link } from 'react-router-dom';


export default class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        const report = props.report;
        
        this.state = {
            cat:[],
            activeCat:'all',
            repoType:'my',
            activeDelete:false,
            allItems:[],
            allCount:0,
            uncatCount:0,
            allCatItems:null
        };

    }

    componentDidMount() {
        const { email } = this.props.userData.getLoadObject().getValue();
        if (email==='baseAccount')
        {
            this.setState({activeDelete:true});
        }
        
        var urlVar = new URL(window.location.href);
        var catName = urlVar.searchParams.get("category");
        if (catName)
        {
            this.setState({'activeCat':catName});
        }
        const response = this.cat();
    }

    componentWillReceiveProps(nextProps, nextState){

        if (nextProps.repoType)
            this.setState({'repoType':nextProps.repoType,allCatItems:null});

        var urlVar = new URL(window.location.href);
        var catName = urlVar.searchParams.get("category");
        if (catName)
        {
            this.setState({'activeCat':catName});
        }
        else
            this.setState({'activeCat':'all'});
        const response = this.cat();

    }

    async cat() {
        var total = 0;
        var reportIDs = [];
        const response = await axios.get(url('categories'));

        var urlVar = new URL(window.location.href);
        var repoType = urlVar.searchParams.get("repoType");

        var cat=null;
        if (this.state.allCatItems)
        {
            cat = this.state.allCatItems;
        }
        else
        {
            cat = await this.gitAllitems();
            await this.setState({allCatItems:cat});
        }
        const { _id } = this.props.userData.getLoadObject().getValue();
        const { email } = this.props.userData.getLoadObject().getValue();

        const cats = [];
        for (var i = 0; i < response.data.length; i++) {

            //const cat = await this.gitAllitems(response.data[i].name);
            //response.data[i].countCategories = cat.length;
            var counter = 0;
            for (var j = 0; j < cat.length; j++)
            {
                if (email != 'baseAccount')
                {
                    if (repoType=='my' && cat[j].ownerId != _id)
                        continue;

                    if (repoType=='shared' && cat[j].ownerId == _id)
                        continue;
                }
                
                if (cat[j].categories.indexOf(response.data[i].name)>-1)
                {
                    counter = Number(counter)+Number(1);
                    response.data[i].countCategories = counter;
                    if (reportIDs.indexOf(cat[j]._id) === -1)
                        total = Number(total) + Number(1);
                        //total = Number(total) + Number(cat.length);    

                    //if (reportIDs.indexOf(cat.id) == -1)
                    reportIDs.push(cat[j]._id);    
                }
                else
                    response.data[i].countCategories = counter;
                
            }
            cats.push(response.data[i]);

        }
        /*var cat=null;
        if (this.state.allCatItems)
        {
            cat = this.state.allCatItems;
        }
        else
        {
            cat = await this.gitAllitems();
            await this.setState({allCatItems:cat});
        }*/
        
        var uncatCount=0;
        // start count of uncategories 
        for (var k = 0; k < cat.length; k++)
        {
            if (cat[k].categories.length===0)
            {
                uncatCount = Number(uncatCount)+Number(1);
            }
            
        }
        // end of count uncategories

        this.setState({'cat':response.data,allCount:total,uncatCount:uncatCount});
        return response;
    }

    changeCatt(catID=22) {
        this.setState({activeCat:catID})
    }

    deleteCat(catID=0) {
        this.getCallApi(catID);
    }

    async getCallApi(catID=0){
        let urlPage = url('categories/'+catID);
        await axios.delete(urlPage)
            .then(json => {
                let response = json.data;
                this.cat();
            });
    }

    async gitAllitems(catName=null) {
        
        const { email } = this.props.userData.getLoadObject().getValue();

        let urll = url('reports/none/list');
        if (catName)
            urll = urll+'/'+catName;
        else
            urll = urll+'/none';
        
        var urlVar = new URL(window.location.href);
        var repoType = urlVar.searchParams.get("repoType");
        //search
        urll = urll+'/none';

        if (email == 'baseAccount')
            repoType = 'admin';

        if (repoType)
            urll = urll + '/'+repoType;
        else
            urll = urll + '/'+this.state.repoType;
        
        let allItemsArr = [];
        let allItems = await axios.get(urll);
        if (typeof allItems.data.length !=='undefined')
            var count = allItems.data;
        else
            var count = 0;
        return count;
        /*let lengthCat = {catName:2};
        
        allItemsArr.push(lengthCat);
        this.setState({allItems:allItemsArr});*/
        //return allItems.length;
    }

    render() {
        
        return (
            <ul>
                <li onClick={() => this.changeCatt('all')} className={this.state.activeCat=='all'?'select':''}><Link to={'/?category=all&repoType='+this.state.repoType}>All ({Number(this.state.allCount)+Number(this.state.uncatCount)})</Link></li>
                {
                    this.state.cat.map(row=> 
                         {
                            if (row.countCategories>0)
                            return <li id={'cat_'+row.id} className={this.state.activeCat==row.name?'select':''} key={row.id}><Link onClick={() => this.changeCatt(row.name)} to={'/?category='+encodeURI(row.name)+'&repoType='+this.state.repoType}>{row.name} ({row.countCategories})</Link><span style={{display:this.state.activeDelete?'block':'none'}} className="del" onClick={e =>
        window.confirm("Are you sure you wish to delete this item?") &&
        this.deleteCat(row.id)}><i></i></span></li>;
                            else
                                return null;

                         }
                    )

                }
                
                <li onClick={() => this.changeCatt('uncategorized')} className={this.state.activeCat=='uncategorized'?'select':''}><Link to={'/?category=uncategorized&repoType='+this.state.repoType}>Uncategorized ({this.state.uncatCount})</Link></li>
            </ul>
            );
    }
    
}   