import React from 'react';
import { Route, Redirect } from 'react-router';

function PrivateRoute({ component, ...rest }) {
    const { userData } = rest;
    const Component = component;
    const user = userData.getLoadObject().getValue();
    return (
        <Route
            {...rest}
            render={props =>
                user ? (
                    <Component {...rest} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}

export default PrivateRoute;