import React from 'react';
import * as FlexmonsterReact from 'react-flexmonster';
import EditReportModal from './main/modals/EditReportModal';
import moment from 'moment';
import { Prompt, withRouter, Redirect } from 'react-router';
import { Header, SymbolButton, CenteredLoadingView } from './util';
import { Modal } from 'react-bootstrap';

class ReportView extends React.Component {
    constructor(props) {
        super(props);

        const { reportId } = props.computedMatch.params;

        let report = null;
        if (reportId.startsWith('new')) {
            const dataSource = reportId.split(':')[1];
            this.dataSource = dataSource;
            report = { dataSource: { dataSourceType: 'csv', filename: `/api/data/${dataSource}` } };
            this.state = {report, isNew: true};
        } else {
            this.reportId = reportId;
            report = `/api/reports/${this.reportId}/content`;
            this.state = {report: null, isNew: false};
        }

        this.state.unsavedChanges = false;
        this.state.newReport = null;

        this.initialReport = report;
        this.flexmonster = React.createRef();
    }

    componentWillUnmount() {
        this.flexmonster.current.flexmonster.clear();
    }

    onRefreshReport = () => {
        const currentReport = this.getCurrentReport(false);
        currentReport.dataSource.filename = currentReport.dataSource.filename + '?refresh=true&' + Math.floor(Math.random() * 10000);
        this.flexmonster.current.flexmonster.setReport(currentReport);
    }

    render() {
        let reportLO = null;
        let report = null;
        if (this.reportId) {
            reportLO = this.props.reports.get(this.reportId);
            report = reportLO.getValue();

            if (!report) {
                return <CenteredLoadingView />;
            }
        }
        if (this.state.fakeId) {
            const createdReport = this.props.reports.find(v => v.getValue().fakeId === this.state.fakeId);
            if (createdReport) {
                this.setState({fakeId: null});
                this.reportId = createdReport.getValue()._id;
                return <Redirect to={'/report/'+createdReport.getValue()._id}/>;
            }
        }

        const reportName = report ? report.name : "New Report";
        return (
        <>
            <Prompt when={this.state.unsavedChanges} message="You have unsaved changes. Are you sure you want to leave?" />
            <Header {...this.props} >
                <h5 className="m-0" style={{color: 'white'}}>
                    {reportName}
                </h5>
                <SymbolButton className="ml-4" text="Refresh data" symbol="↻" onClick={this.onRefreshReport}/>
            </Header>
            <div style={{height: '100vh'}}>
                <FlexmonsterReact.Pivot
                    licenseKey="Z7HH-XIG73L-63624G-4W4L5G-2I3D40-2I6542-4Q6I2J-5A0S34-4W1S3H-6H4T"
                    height='100%'
                    toolbar
                    report={this.initialReport}
                    beforetoolbarcreated={this.customizeToolbar}
                    reportchange={this.onReportChanged}
                    ref={this.flexmonster} />
            </div>
            <Modal show={this.state.saving} onHide={() => this.setState({saving: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>Saving {reportName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        reportLO && reportLO.isDone() ? (reportLO.getError() ? "An error ocurred while trying to save the report: " + reportLO.getError().message : "Report saved successfully") : <CenteredLoadingView />
                    }
                </Modal.Body>
            </Modal>
            <Modal show={!!this.state.fakeId} onHide={() => this.setState({fakeId: null})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Creating new report...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.trackCreateStatus()
                        }
                    </Modal.Body>
            </Modal>
            {
                this.state.newReport ?
                <EditReportModal
                    show={!!this.state.newReport}
                    dismissModal={() => this.setState({newReport: null})}
                    createReport={this.onCreateReport}
                    userData={this.props.userData}
                    users={this.props.users}
                    report={this.state.newReport}
                    new />
                : null
            }
        </>);
    }

    trackCreateStatus() {
        if (!this.state.fakeId) {
            return "";
        }

        const fakeReport = this.props.reports.get(this.state.fakeId);
        if (fakeReport) {
            if (fakeReport.getError()) {
                return "Error ocurred while creating report: " + fakeReport.getError().message;
            } else {
                return <CenteredLoadingView />;
            }
        }
    }

    onReportChanged = () => {
        const report = this.flexmonster.current.flexmonster.getReport();
        report.dataSource.filename = report.dataSource.filename.split('?')[0];
        this.setState({ report });
        this.setState({ unsavedChanges: true});
    }

    onCreateReport = (report) => {
        this.setState({isNew: false});
        const fakeId = this.props.getFakeId();
        this.props.createReport(report, fakeId);
        this.setState({fakeId});
    }

    createNewReport() {
	var reportContent = this.state.report;
	if (reportContent === null) {
          reportContent = this.flexmonster.current.flexmonster.getReport();
          reportContent.dataSource.filename = reportContent.dataSource.filename.split('?')[0];
	}
        const report = {
            reportContent: JSON.stringify(reportContent),
            name: `New Report ${new moment().format('YYYY-MMM-DD HH:mm')}`,
            writerIds: [],
            readerIds: [],
            categories: []
        }
        this.setState({newReport: report});
    }

    getCurrentReport(stringify) {
        if (this.state.report) {
            if (stringify) {
                return JSON.stringify(this.state.report);
            } else {
                return this.state.report;
            }
        } else {
            const fromStore = this.props.reports.get(this.reportId).getValue().reportContent;
            if (stringify) {
                return fromStore;
            } else {
                return JSON.parse(fromStore);
            }
        }
    }

    saveReport() {
        const report = this.getCurrentReport(true);
        this.props.updateReport(this.reportId, {reportContent: report});
        this.setState({saving: true});
    }

    saveOnServerHandler(saveAs) {
        if (this.state.isNew || saveAs) {
            this.createNewReport();
        } else {
            this.saveReport();
        }
        this.setState({unsavedChanges: false});
    }

    customizeToolbar = (toolbar) => {
        const tabs = toolbar.getTabs();

        let cutoffIndex = 0;
        for (let i in tabs) {
            if (tabs[i].id === "fm-tab-save") {
                cutoffIndex = i;
            }
        }

        const newTabs = tabs.slice(cutoffIndex);

        for (let i in newTabs) {
            const tab = tabs[i];
            if (tab.id === "fm-tab-save") {
                const localSaveHandler = tab.handler;
                delete tab.handler;
                // Override save button to show option between saving locally or on server.
                tab.menu = [
                    { title: "Save locally", id: "fm-save-locally", handler: localSaveHandler, mobile: false, icon: toolbar.icons.open_local },
                    { title: "Save on server", id: "fm-save-on-server", handler: () => this.saveOnServerHandler(), mobile: false, icon: toolbar.icons.open_local },
                    { title: "Save as on server", id: "fm-save-on-server", handler: () => this.saveOnServerHandler(true), mobile: false, icon: toolbar.icons.open_local },
                ];
            }
        }

        toolbar.getTabs = function () {
            return newTabs;
        }
    }
}

export default withRouter(ReportView);
