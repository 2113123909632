import axios from 'axios';
import KhDispatcher from '../KhDispatcher';
import { url, dispatchError } from './util';

const DataSourcesDataManager = {
    getDataSources: async function() {
        try {
            const response = await axios.get(url('data'));
            KhDispatcher.dispatch({
                type: 'dataSources/loaded',
                data: response.data
            })
        } catch (err) { 
            dispatchError('dataSources/load-error', err);
        }
    }
}

export default DataSourcesDataManager;