import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import LoadObjectOperation from '../load_object/LoadObjectOperation';
import KhDispatcher from '../KhDispatcher';
import LoadObjectMap from '../load_object/LoadObjectMap'
import UserDataManager from '../data_managers/UserDataManager';

class UserStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectMap((ids) => ids.forEach(id => KhDispatcher.dispatch({type: 'user/start-load', id})));
    }

    reduce(state, action) {
        switch(action.type) {
            case 'user/start-load':
                if (action.id === 'all') {
                    UserDataManager.loadAllUsers();
                } else {
                    UserDataManager.getUserFromId(action.id);
                }
                return state.set(action.id, LoadObject.loading());
            case 'user/loaded':
            {
                const userLo = state.get(action.id);
                return state.set(action.id, userLo.setValue(action.data).removeOperation());
            }
            case 'user/load-error':
            {
                const userLo = state.get(action.id);
                return state.set(action.id, userLo.setError(action.error).removeOperation());
            }
            case 'user/delete':
            {
                const userLo = state.get(action.id);
                UserDataManager.deleteUser(action.id);
                return state.set(action.id, userLo.setOperation(LoadObjectOperation.Deleting));
            }
            case 'user/deleted':
            {
                UserDataManager.loadAllUsers();
                return state.delete(action.id);
            }
            case 'user/delete-error':
            {
                const userLo = state.get(action.id);
                return state.set(action.id, userLo.setError(action.error).removeOperation());
            }
            case 'user/update': 
            {
                const userLo = state.get(action.id);
                UserDataManager.updateUser(action.id, action.data);
                return state.set(action.id, userLo.setOperation(LoadObjectOperation.Updating));
            }
            case 'user/updated':
            {
                const userLo = state.get(action.id);
                // TODO: Workaround for the whole 'all' key thing
                UserDataManager.loadAllUsers();
                return state.set(action.id, userLo.setValue(action.data).removeOperation());
            }
            case 'user/create': 
            {
                UserDataManager.createUser(action.data, action.fakeId);
                return state.set(action.fakeId, LoadObject.creating());
            }
            case 'user/created':
            {
                UserDataManager.loadAllUsers();
                return state.delete(action.fakeId).set(action.id, LoadObject.withValue(action.data));
            }
            case 'user/create-error':
            {
                return state.delete(action.fakeId);
            }

            default: 
                return state;
        }
    }

}

export default new UserStore();