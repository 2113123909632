import LoadObjectOperation from './LoadObjectOperation';

class LoadObject {
    constructor(value, error, operation, hasValue, operated) {
        this._value = value;
        this._error = error;
        this._operation = operation;
        this._hasValue = hasValue;
        this._operated = operated;
    }

    getOperation() {
        return this._operation;
    }

    getError() {
        return this._error;
    }

    getValue() {
        return this._value;
    }

    hasOperation() {
        return this._operation !== LoadObjectOperation.None;
    }

    hasValue() {
        return this._hasValue;
    }

    hasError() {
        return this._error !== undefined;
    }

    setOperation(operation) {
        if (this._operation === operation) {
            return this;
        }
        return new LoadObject(this._value, this._error, operation, this._hasValue, this._operated || operation !== LoadObjectOperation.None);
    }

    setValue(value) {
        if (this._value === value) {
            return this;
        }

        return new LoadObject(value, this._error, this._operation, true, this._operated);
    }

    setError(error) {
        if (this._error === error) {
            return this;
        }

        return new LoadObject(this._value, error, this._operation, this._hasValue, this._operated);
    }

    removeOperation() {
        return this.setOperation(LoadObjectOperation.None);
    }

    removeValue() {
        if (this._value === undefined && this._hasValue === false){
            return this;
        }

        return new LoadObject(undefined, this._error, this._operation, false, this._operated);
    }

    removeError() {
        if (this._error === undefined) {
            return this;
        }

        return new LoadObject(this._value, undefined, this._operation, this._hasValue, this._operated);
    }

    isDone() {
        return !this.hasOperation();
    }

    hasOperated() {
        return this._operated;
    }

    isEmpty() {
        return !(this.hasValue() || this.hasError() || this.hasOperation());
    }

    static empty() {
        return new LoadObject(undefined,
            undefined,
            LoadObjectOperation.None,
            false,
            false);
    }

    static creating() {
        return new LoadObject(undefined,
            undefined,
            LoadObjectOperation.Creating,
            false, 
            true);
    }

    static loading() {
        return new LoadObject(undefined,
            undefined,
            LoadObjectOperation.Loading,
            false,
            true);
    }

    static updating() {
        return new LoadObject(undefined,
            undefined,
            LoadObjectOperation.Updating,
            false,
            true);
    }

    static deleting() {
        return new LoadObject(undefined,
            undefined,
            LoadObjectOperation.Deleting,
            false,
            true);
    }

    static withError(error) {
        return new LoadObject(
            undefined,
            error,
            LoadObjectOperation.None,
            false, 
            true);
    }

    static withValue(value) {
        return new LoadObject(
            value,
            undefined,
            LoadObjectOperation.None,
            true);
    }
}

export default LoadObject;