import React from 'react';
import { LoadingView } from '../util';
import { Separator } from '../util';
import { Button, Modal } from 'react-bootstrap';
import EditUserModal from './modals/EditUserModal';
import '../../css/components/users_list.css'
import '../../css/main.css'

class UserItem extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {deleting: false, editing: false};
    }
    render() {
        const u = this.props.user;
        const displayName = u.name || u.email;
        const onDelete = () => this.setState({deleting: true});
        const onEdit = () => this.setState({editing: true});

        return (
            <li className="list-group-item p-1 user-item flex-sb">
                <div className="mr-2 text-ellipsis user-name" title={displayName}>{displayName}</div>
                <span>
                    <button  className="btn btn-link p-0" onClick={onEdit}>Edit</button>
                    {
                        u.email === 'baseAccount' ? null : 
                        (<><Separator />
                        <button onClick={onDelete} className="btn btn-link p-0">Remove</button></>)
                    }
                </span>
                <Modal show={this.state.deleting} onHide={() => this.setState({deleting: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Deleting {displayName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Are you sure you want to delete {displayName}?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({deleting: false})} variant="primary">Close</Button>
                        <Button variant="danger" onClick={() => { this.props.deleteUser(u._id); this.setState({deleting: false}); }}>Delete account</Button>
                    </Modal.Footer>
                </Modal>
                <EditUserModal show={this.state.editing} dismissModal={() => this.setState({editing: false})} updateUser={this.props.updateUser} user={u} />
            </li>
        );
    }
}

class UsersList extends React.Component {
    constructor() {
        super();
        this.state = {collapsed: true, adding: false};
    }
    render() {
        const allUsers = this.props.users.get('all').getValue();
        if (!allUsers) {
            return <LoadingView />;
        }
        const onAdd = () => this.setState({adding: true});

        const { _id } = this.props.userData.getLoadObject().getValue();
        const { email } = this.props.userData.getLoadObject().getValue();
        
        return (
            <div>
                <Button active={!this.state.collapsed} variant="secondary" onClick={() => this.setState({ collapsed: !this.state.collapsed })}> Users </Button>
                {
                    this.state.collapsed === false ? 
                            <ul className="p-0 user-list">
                                <li className="list-group-item p-1 user-item flex-end">
                                    <Button className="add-button p-0" variant='link' onClick={onAdd}>Add</Button>
                                </li>
                                {allUsers.map(u => 
                                    {
                                        if (_id==u._id || email == 'baseAccount')
                                        return (<UserItem 
                                                deleteUser={this.props.deleteUser}
                                                updateUser={this.props.updateUserx}
                                                createUser={this.props.createUser}
                                                key={u._id}
                                                user={u} />)
                                        else
                                            return null;
                                    }
                                    
                                )}
                            </ul>
                    : 
                        null
                }
                <EditUserModal show={this.state.adding} dismissModal={() => this.setState({adding: false})} createUser={this.props.createUser} new />
                <div className='clearfix'></div>

            </div>);
    }
}

export default UsersList;