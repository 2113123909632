import { Container } from 'flux/utils';
import UserDataStore from '../stores/UserDataStore';
import LoginStatusStore from '../stores/LoginStatusStore';
import ReportListStore from '../stores/ReportListStore';
import UserStore from '../stores/UserStore';
import DataSourcesStore from '../stores/DataSourcesStore';
import KhDispatcher from '../KhDispatcher';
import App from '../App';
import ReportStore from '../stores/ReportStore';

function getStores() {
    return [
        UserDataStore,
        LoginStatusStore,
        ReportListStore,
        ReportStore,
        UserStore,
        DataSourcesStore
        
    ];
}

function getState() {
    const userData = UserDataStore.getState();
    const loginStatus = LoginStatusStore.getState();
    const reportList = ReportListStore.getState();
    const reports = ReportStore.getState();
    const users = UserStore.getState();
    const dataSources = DataSourcesStore.getState();

    const userLoadObject = userData.getLoadObject();
    const isReady = userLoadObject.hasOperated() && userLoadObject.isDone();

    return {
        userData,
        loginStatus,
        users,
        isReady,
        reportList,
        reports,
        dataSources,
        onLogin: (email, password) => KhDispatcher.dispatch({ type: 'login/start', email, password}),
        onLogout: () => KhDispatcher.dispatch({type: 'userData/logout'}),
        deleteUser: (id) => KhDispatcher.dispatch({type: 'user/delete', id}),
        updateUser: (id, data) => KhDispatcher.dispatch({type: 'user/update', id, data}),
        createUser: (data) => KhDispatcher.dispatch({type: 'user/create', data, fakeId: getFakeId()}),
        updateReport: (id, data) => KhDispatcher.dispatch({type: 'report/update', id, data}),
        createReport: (data, fakeId) => KhDispatcher.dispatch({type: 'report/create', data, fakeId}),
        deleteReport: (id) => KhDispatcher.dispatch({type: 'report/delete', id}),
        getFakeId
    };
}

function getFakeId() {
    return Math.random() * 10000;
}

export default Container.createFunctional(App, getStores, getState);