import { ReduceStore } from 'flux/utils';
import LoadObject from '../load_object/LoadObject';
import KhDispatcher from '../KhDispatcher';
import LoadObjectState from '../load_object/LoadObjectState'
import UserDataManager from '../data_managers/UserDataManager';

class UserDataStore extends ReduceStore {
    constructor() {
        super(KhDispatcher);
    }

    getInitialState() {
        return new LoadObjectState(() => KhDispatcher.dispatch({type: 'userData/load'}));
    }

    reduce(state, action) {
        switch(action.type) {
            case 'userData/logout':
                UserDataManager.logoutUser();
                return state;
            case 'userData/logged-out':
                window.location.reload();
                return state;
            case 'userData/logout-error':
            case 'userData/load':
                UserDataManager.loadUserData();
                return state.setLoadObject(LoadObject.loading());
            case 'userData/loaded':
                return state.setLoadObject(state.getLoadObject().setValue(action.data).removeOperation());
            case 'userData/error':
                return state.setLoadObject(state.getLoadObject().setError(action.error).removeOperation());
            default:
                return state;
        }
    }
};

export default new UserDataStore();