import axios from 'axios';
import KhDispatcher from '../KhDispatcher';
import { url, dispatchError } from './util';

const UserDataManager = {
    async loadUserData() {
        try {
            let response = await axios.get(url('user'));
            KhDispatcher.dispatch({
                type: 'userData/loaded',
                data: response.data
            });
        } catch (err) {
            dispatchError('userData/error', err);
        }
    },
    async logIn(email, password) {
        try {
            let response = await axios.post(url('login'), {email, password})
            KhDispatcher.dispatch({
                type: 'login/success'
            });

            KhDispatcher.dispatch({
                type: 'userData/loaded',
                data: response.data
            });
        } catch (err) {
            if (err.response && err.response.status === 401) {
                KhDispatcher.dispatch({ type: 'login/error', error: { message: "Username or password incorrect" }});
                return;
            }
            dispatchError('login/error', err);
        }
    },
    async getUserFromId(id) {
        try {
            let response = await axios.get(url('users/' + id));
            KhDispatcher.dispatch({
                type: 'user/loaded',
                id,
                data: response.data
            });
        } catch (err) {
            dispatchError('user/load-error', err, id);
        }
    },
    async loadAllUsers() {
        try {
            let response = await axios.get(url('users'));
            KhDispatcher.dispatch({type: 'user/loaded', id: 'all', data: response.data});
            response.data.forEach(u => KhDispatcher.dispatch({type: 'user/loaded', id: u._id, data: u}));
        } catch (err) {
            dispatchError('users/load-error', err);
        }
    },
    async deleteUser(id) {
        try {
            await axios.delete((url('users/'+id)));
            KhDispatcher.dispatch({type: 'user/deleted', id: id});
        } catch (err) {
            dispatchError('user/delete-error', err, id);
        }
    },
    async updateUser(id, values) {
        try {
            const response = await axios.put(url('users/'+id), {user: values});
            KhDispatcher.dispatch({type: 'user/updated', id: id, data: response.data});
        } catch (err) {
            dispatchError('user/update-error', err, id);
        }
    },
    async createUser(values, fakeId) {
        try {
            const response = await axios.post(url('users/'), {user: values});
            const data = response.data;
            KhDispatcher.dispatch({type: 'user/created', id: data._id, data, fakeId});
        } catch (err) {
            dispatchError('user/create-error', err, undefined, fakeId);
        }
    }, 
    async logoutUser() {
        try {
            await axios.get(url('logout/'));
            KhDispatcher.dispatch({type: 'userData/logged-out'});
        } catch (err) {
            dispatchError('userData/log-out-error', err);
        }
    }
};

export default UserDataManager;