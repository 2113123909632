import React from 'react';
import '../css/main.css';
import DataSourcesList from './main/DataSourcesList';
import UsersList from './main/UsersList';
import ReportList from './main/ReportList';
import ReportListing from './main/ReportListing';
import { Header } from './util';
import { Link } from 'react-router-dom';
import CategoryLists from './main/CategoryList';

import axios from 'axios';
import { url, dispatchError } from './util';
import Pagination from "react-js-pagination";


export default class MainView extends React.Component {
    constructor(props) {
        super(props);

        const pageNum = props.computedMatch.params.pageNum;

        this.state = {person: [],activePage:15,pageNum:pageNum,repoType:'my',loading:false};
        this.reportsType = this.reportsType.bind(this);
    }

  componenWillMount() {
    var urlVar = new URL(window.location.href);
    var repoType = urlVar.searchParams.get("repoType");
    if (repoType)
    {
        this.setState({'repoType':repoType});
    }
  }
  componentDidMount() {
    
  }

    componentDidUpdate(prevProps, prevState) {
        
        var urlVar = new URL(window.location.href);
        var repoType = urlVar.searchParams.get("repoType");
        if (repoType && this.state.repoType != repoType)
        {
            this.setState({'repoType':repoType});
        }

    if (prevState.pageNum != this.props.computedMatch.params.pageNum)
    {
        this.setState({pageNum:this.props.computedMatch.params.pageNum});
    }
    
    }
  
  componentWillReceiveProps(nextProps, nextState){

  }

  reportsType(target,e){
        //e.preventDefault();
        this.setState({
            loading: true
        });
        switch(target){
            case 'my':
                
                    this.setState({repoType:'my'});
                
            break;
            case 'shared':
                
                    this.setState({repoType:'shared'});
                
            break;
        }
        this.setState({
            loading: false
        });
        
    }
    
    render() {

        const userData = this.props.userData.getLoadObject();
        
        return (
            <>
                <Header {...this.props} />
                <div className='col-md-12 container-add-icon'>
                    
                <DataSourcesList {...this.props}/>
                <div className='col-md-3 right'>
                    <div className='col-md-12 userButton'>
                        <UsersList 
                            createUser={this.props.createUser}
                            updateUser={this.props.updateUserx} 
                            deleteUser={this.props.deleteUser} 
                            users={this.props.users} 
                            userData={this.props.userData} />
                        <div className='clearfix'></div>
                    </div>
                    
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle buttonCollr" type="button" id="menu5" data-toggle="dropdown" aria-expanded="true">{userData.getValue().name}
                          <span className="glyphicon glyphicon-asterisk"></span>
                                </button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a onClick={this.props.onLogout} className="dropdown-item" href="#about">Logout</a>
                          </div>
                    </div>
                    <div className='clearfix'></div>
                </div>
                <div className='clearfix'></div>

                </div>
                <div className='center-container col-md-12'>
                    <div className='col-md-2 bg-color-boxs' style={{maxWidth:'14.666667%'}}>
                        <h1>Main</h1>
                        <ul className='mylist'>
                            <li className={this.state.repoType=='all'?'select':''}><Link onClick={(e)=>this.reportsType('all',e)} to={'/?category=all&repoType=all'}>All</Link></li>
                            <li className={this.state.repoType=='my'?'select':''}><Link onClick={(e)=>this.reportsType('my',e)} to={'/?category=all&repoType=my'}>My Reports</Link></li>
                            <li className={this.state.repoType=='shared'?'select':''}><Link onClick={(e)=>this.reportsType('shared',e)} to={'/?category=all&repoType=shared'}>Shared With me</Link></li>
                        </ul>

                    </div>
                    <div className='col-md-2 bg-color-boxs'>
                        <h1>Categories</h1>
                        <ul>
                            {/*<li><Link to='#'>Apps</Link></li>
                            <li className='select'><Link to='#'>Finance</Link></li>*/}
                            <CategoryLists repoType={this.state.repoType} userData={this.props.userData} />
                        </ul>
                    </div>
                    {/*<ReportList 
                        className='mr-4 col-md-8 nopadding' 
                        userData={this.props.userData} 
                        users={this.props.users} 
                        reportList={this.props.reportList} 
                        reports={this.props.reports}
                        updateReport={this.props.updateReport}
                        deleteReport={this.props.deleteReport}
                        linkFun={Link}
                        pageNum={this.state.pageNum} />*/}
                        <ReportListing  users={this.props.users} 
                                        userData={this.props.userData} 
                                        users={this.props.users} 
                                        reportList={this.props.reportList} 
                                        reports={this.props.reports}
                                        updateReport={this.props.updateReport}
                                        deleteReport={this.props.deleteReport}
                        />
                        {/*<DataSourcesList {...this.props} />*/}
                </div>
                 
                <div className=' col-md-12'>


                    
                </div>
            </>
        );
    }
}