import React from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { url, dispatchError } from '../../../data_managers/util';

export default class EditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.email = React.createRef();
        this.name = React.createRef();
        this.password = React.createRef();
        this.confirm = React.createRef();
        this.state = this.getInitialState();
    }

    getInitialState() {

        return {
            error: null,
            errorStatus:false
        };
    }
    
    async componentWillReceiveProps(nextProps, nextState){
        await this.setState({error: null,errorStatus:false});
    }
    async updateUser (id,values) {
        const response = await axios.put(url('users/'+id), {user: values});
        console.log('Update',id,values,response);

    }
    async createUser (values) {
        
        try {
          const response = await axios.post(url('users/'), {user: values});

        } catch (e) {
            await this.setState({errorStatus:true});
            var error = e.response.data.errors.email?e.response.data.errors.email:e.response.data.errors.password;
          await this.setState({error: error,errorStatus:true});
        }
    }


    render() {
        const title = this.props.new ? 'Add user' : 'Edit ' + this.props.user.name || this.props.user.email ;
        const onSave = async () => {
            const password = this.password.current.value;
            const confirm = this.confirm.current.value;
            if (password !== confirm) {
                this.setState({error: 'Password and confirm password do not match.'});
                return;
            }

            const email = this.email.current.value;
            if (email !== 'baseAccount' && !(/\S+@\S+\.\S+/).test(email)) {
                this.setState({error: 'Invalid email'});
                return;
            }

            const user = {
                    email: this.email.current.value,
                    name: this.name.current.value,
                    password: password === 'password' || password === '' ? undefined : password
            }
            
            if (this.props.new) {
                var addAction = await this.createUser(user);
                //this.props.createUser(user);
                if (this.state.errorStatus===true)
                {
                    return; 
                }
                 
                const data = addAction;
            } else {
                //this.props.updateUser(this.props.user._id, user);
                var updateAction = this.updateUser(this.props.user._id, user);
            }
            this.props.dismissModal();
        }

        const onCancel = () => {
            this.setState(this.getInitialState());
            this.props.dismissModal();
        }

        return (
            <Modal show={this.props.show} onHide={this.props.dismissModal}>
                <Modal.Header>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form autoComplete="off">
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control disabled={(this.props.user && this.props.user.email) === "baseAccount"} defaultValue={this.props.new ? '' : this.props.user.email} type="email" size="sm" ref={this.email} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control defaultValue={this.props.new ? '' : this.props.user.name} type="text" size="sm" ref={this.name} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control placeholder='password' type="password" size="sm" ref={this.password} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control placeholder='password' type="password" size="sm" ref={this.confirm} />
                        </Form.Group>
                    </Form>
                    {this.state.error ? <Alert variant='danger'>{this.state.error}</Alert> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onCancel}>Cancel</Button>
                    <Button variant='primary' onClick={onSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}