import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Header } from './util';
import '../css/login.css';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '' };
    }
    render() {
        const loginStatus = this.props.loginStatus.getLoadObject();
        const userData = this.props.userData.getLoadObject();
        const loggedIn = userData.getValue();
        const disableForm = !loginStatus.isDone() || loggedIn;
        const { from } = (this.props.location && this.props.location.state) || { from: { pathname: "/" } };

        const onEmailChange = (event) => {
            this.setState({ email: event.target.value });
        }

        const onPasswordChange = (event) => {
            this.setState({ password: event.target.value });
        }

        const onSubmit = (event) => {
            this.props.onLogin(this.state.email, this.state.password);
            this.setState({attemptedLogin: true});
            event.preventDefault();
        }

        if (loggedIn && this.state.attemptedLogin) {
            return <Redirect to={from} />;
        }

        let statusMessage = null;
        if (loginStatus.hasError()) {
            statusMessage = <div className="alert alert-danger">{loginStatus.getError().message}</div>
        } else if (loggedIn) {
            statusMessage = (
                <div className="alert alert-primary"><div>You are already logged in as <strong>{userData.getValue().email}</strong>
                    </div><Link className="alert-link" to={from}>Go back</Link>
                </div>
            );
        }
        
        return (
            <div  className="login-form">
                <fieldset disabled={disableForm}>
                    <legend>Login</legend>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="email" value={this.state.email}
                                onChange={onEmailChange}
                                placeholder="Email" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                required
                                type="password"
                                id="password"
                                className="form-control"
                                value={this.state.password}
                                onChange={onPasswordChange}
                                placeholder="Password" />
                        </div>
                        <div className="form-group">
                            <input
                                value="Log in"
                                type="submit"
                                className="btn btn-primary" />
                        </div>
                        {statusMessage}
                    </form>
                </fieldset>
            </div>
        );
    }
}

class LoginView extends React.Component {
    render() {
        return (
            <>
                <Header userData={this.props.userData}  onLogout={this.props.onLogout} />
                <div className="container">
                    <LoginForm {...this.props} />
                </div>
            </>
        );
    }
}

export default LoginView;