import LoadObject from './LoadObject';

class LoadObjectState {
    constructor(load, shouldLoad) {
        this._data = LoadObject.empty();
        this._load = load;
        this._shouldLoad = shouldLoad || (lo => lo.isEmpty());
        this._preventLoadsForThisFrame = false;
        this._clearPreventLoadsForThisFrame = null;
    }

    getLoadObject() {
        if (!this._preventLoadsForThisFrame && this._shouldLoad(this._data)) {
            this._preventLoadsForThisFrame = true;
            this._clearPreventLoadsForThisFrame = setTimeout(
                () => {
                    this._load();
                    this._preventLoadsForThisFrame = false;
                    this._clearPreventLoadsForThisFrame = null;
                },
                0
            );
        }

        return this._data;
    }

    setLoadObject(lo) {
        if (lo === this._data) {
            return this;
        }

        const next = new LoadObjectState(this._load, this._shouldLoad);
        next._data = lo;
        return next;
    }
}

export default LoadObjectState;