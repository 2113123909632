import React from 'react';
import { CenteredLoadingView, EmptyListText, ListHeader, SymbolButton } from '../util';
import { Link } from 'react-router-dom';
import '../../css/components/data_sources_list.css';

function DataSourcesListItemLinks(props) {
    return (
        
        <div className="dropdown">
          <button className="btn btn-primary dropdown-toggle buttonCollr" id="dropdownMenuButton" type="button" id="menu1" data-toggle="dropdown" aria-expanded="true">{props.d}
          <span className="glyphicon glyphicon-asterisk"></span>
                </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link to={'report/new:' + props.d} className="dropdown-item">New Report</Link>
            <Link to={"data/" + props.d} className="dropdown-item">View Data</Link>
          </div>
        </div>
    );
}

class DataSourcesList extends React.Component {
    render() {

        
        const lo = this.props.dataSources.getLoadObject();
        const dataSources = lo.getValue()


        let body = null;

        if (lo.isDone() && lo.hasOperated()) {
            if (dataSources) {
                if (dataSources.length === 0) {
                    body = <EmptyListText>No data sources configured.</EmptyListText>;
                } else {
                    body = (
                        <div className='col-md-12' style={{paddingLeft:0}}>
                            {dataSources.map(d => <DataSourcesListItemLinks key={d} d={d} />)}
                            <div className='clearfix'></div>
                        </div>
                    );
                }
            } else {
                body = <EmptyListText>Error loading data sources.</EmptyListText>
            }
        } else {
            body = <CenteredLoadingView />;
        }
        return (
                <div className='col-md-9' style={{paddingLeft:0}}>
                    {body}
                </div>
        );
    }
}

export default DataSourcesList;